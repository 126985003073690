
.print-box {
    display: flex;
    justify-content: center;
  }

  .print-box button {
    border: none;
    background-color: orange;
    padding: 0.5rem 1.3rem;
    font-size: 0.85vw;
    color: white;
    border-radius: 0.3rem;
    cursor: pointer;
    transition: 0.4s linear all;
    border: orange solid 1px;
  }

  .print-box button:hover {
    background-color: white;
    color: orange;
  }


